import MTPLService from '../../services/MTPLService'
import router from '@/router/index'
import i18n from '@/plugins/i18n'
import { claimFiltersToParams } from './../helpers'
import * as Sentry from '@sentry/vue'

// TODO: refactor to claim module instead of MTPL only.

export const namespaced = true
export const state = {
  claims: [],
  mtpls: {
    claims: [],
  },
  claim: null,
  pagination: {
    pages: null,
    currentPage: null,
    records: null,
    newRecords: null,
  },
}
export const getters = {
  value: (state) => {
    return state.value
  },
  followers: (state) => {
    return state.claim?.followers ? state.claim.followers : []
  },
}
export const mutations = {
  SET_MTPLS(state, mtpls) {
    state.mtpls.claims = mtpls
  },
  CLEAR_MTPLS(state) {
    state.mtpls.claims = []
  },
  SET_CLAIMS(state, claims) {
    state.claims = claims
  },
  REMOVE_CLAIM(state, claimId) {
    state.mtpls.claims = state.mtpls.claims.filter(
      (claim) => claim.id != claimId
    )
  },
  SET_CLAIM(state, claim) {
    state.claim = claim
  },
  SET_FOLLOWERS(state, followers) {
    state.claim.followers = followers
  },
  SET_COLOUR(state, colour) {
    state.claim = { ...state.claim, colour }
  },
  SET_PAGINATION(state, headers) {
    state.pagination = {
      pages: parseInt(headers['x-pages']),
      currentPage: parseInt(headers['x-page']),
      records: parseInt(headers['x-records']),
      newRecords: parseInt(headers['x-new-records']),
    }
  },
  UPDATE_CLAIM_STAGE_ID(state, { id, stageId, stage }) {
    state.mtpls.claims = state.mtpls.claims.map((c) => {
      if (c.id == id) {
        return {
          ...c,
          stage: stage,
          stageId: stageId,
        }
      } else {
        return c
      }
    })
  },
  DO_NOTHING() {},
}
export const actions = {
  updateValue({ commit }, payload) {
    commit('updateValue', payload)
  },
  async changeStage({ commit }, payload) {
    commit('DO_NOTHING')
    return MTPLService.changeStage(payload)
  },
  async updateStageToCurrent({ commit }, claim) {
    commit('UPDATE_CLAIM_STAGE_ID', claim)
  },
  async unfollowClaim({ commit }, id) {
    MTPLService.unfollowClaim(id).then((response) => {
      commit('SET_FOLLOWERS', response.data.claim.followers)
    })
    //TODO: Add a snackbar
  },
  async followClaim({ commit }, id) {
    MTPLService.followClaim(id).then((response) => {
      commit('SET_FOLLOWERS', response.data.claim.followers)
    })
    //TODO: Add a snackbar
  },
  async getClaims(
    { commit, dispatch },
    {
      pipelineId,
      orderBy = '',
      page = 1,
      pageSize = 10000,
      filters = {},
      claimSearch = '',
    }
  ) {
    let filtersWithClaimSearchParam = { ...filters, claimSearch }
    let params = claimFiltersToParams(filtersWithClaimSearchParam)

    //TODO: Remove unwanted fields from pipelined items.
    // if (rootState.user.user) {

    return MTPLService.getClaims(pipelineId, orderBy, page, pageSize, params)
      .then((response) => {
        let paginationData = Object.assign(response.headers)
        commit('SET_PAGINATION', paginationData)
        const concatVINs = (cargoVehicles) => {
          let VINs = ''
          if (cargoVehicles.length == 1) {
            if (cargoVehicles[0].vin) VINs = cargoVehicles[0].vin
          } else {
            cargoVehicles.forEach((vehicle) => {
              VINs += vehicle.vin + ' '
            })
          }
          return VINs.trimEnd()
        }
        let claims = response.data.claims.map((claim) => {
          return {
            ...claim,
            vehicleVINs: claim.cargoVehicles
              ? concatVINs(claim.cargoVehicles)
              : '',
          }
        })
        commit('SET_MTPLS', claims)
      })
      .catch((err) => {
        if (err.response.status === 401) {
          // const notification = {
          //   type: 'error',
          //   message:
          //     'Unauthorized access detected: ' + err.response.data.message,
          // }
          // dispatch('notification/add', notification, { root: true })
          dispatch('user/logout', null, { root: true })
        } else {
          // const notification = {
          //   type: 'error',
          //   message:
          //     'There was an error getting Bundles: ' +
          //     err.response.data.message,
          // }
          // dispatch('notification/add', notification, { root: true })
        }
      })
    // }r
  },
  async getClaimNumber({ commit }, pipelineId) {
    commit('DO_NOTHING')
    return MTPLService.getClaimNumber(pipelineId)
  },
  async deleteClaim({ commit, dispatch }, claimId) {
    MTPLService.deleteClaim(claimId)
      .then((response) => {
        let snackbar = {
          text: `${i18n.t('snackbar.claimDeletedSuccessfully')}.`,
          color: 'success',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
        commit('REMOVE_CLAIM', response.data.claim.id)
      })
      .catch(() => {
        let snackbar = {
          text: `${i18n.t('snackbar.claimWasNotDeleted')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
      })
  },
  async updateClaim({ commit, dispatch }, claim) {
    return MTPLService.updateClaim(claim.id, claim)
      .then((response) => {
        let snackbar = {
          text: `${i18n.t('snackbar.claimUpdatedSuccessfully')}.`,
          color: 'success',
        }
        dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
        return response
      })
      .catch((err) => {
        Sentry.captureMessage(`Update failed: ${err}`, (scope) => {
          scope.setExtras({ ...claim, stringified: JSON.stringify(claim) })
        })
        let snackbar = {
          text: `${i18n.t('snackbar.claimUpdateFailed')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
      })
      .finally(() => {
        commit('DO_NOTHING')
      })
  },

  async changeColour({ commit, dispatch }, { id, colour = null }) {
    return MTPLService.updateClaim(id, { colour })
      .then((response) => {
        let snackbar = {
          text: `${i18n.t('snackbar.claimUpdatedSuccessfully')}.`,
          color: 'success',
        }
        dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
        commit('SET_COLOUR', response.data.claim.colour)
        return response
      })
      .catch(() => {
        let snackbar = {
          text: `${i18n.t('snackbar.claimUpdateFailed')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
      })
  },
  // async createClaim({ commit }, claim) {
  //   MTPLService.createClaim(claim).then((response) => {
  //     router.push(`/mtpl/${response.data.claim.id}`)
  //   })
  //   commit('DO_NOTHING')
  // },
  async createMTPLClaim({ commit, dispatch }, claim) {
    MTPLService.createClaim(claim)
      .then((response) => {
        router.push(`/mtpl/${response.data.claim.id}`)
      })
      .catch((err) => {
        Sentry.captureMessage(`Create MTPL Liable failed: ${err}`, (scope) => {
          scope.setExtras({ ...claim, stringified: JSON.stringify(claim) })
        })
        let snackbar = {
          text: `${i18n.t('snackbar.claimCreationFailed')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
      })
      .finally(() => {
        commit('DO_NOTHING')
      })
  },
  async createCMRClaim({ commit, dispatch }, claim) {
    MTPLService.createClaim(claim)
      .then((response) => {
        router.push(`/cmr/${response.data.claim.id}`)
      })
      .catch((err) => {
        Sentry.captureMessage(`Create CMR failed: ${err}`, (scope) => {
          scope.setExtras({ ...claim, stringified: JSON.stringify(claim) })
        })
        let snackbar = {
          text: `${i18n.t('snackbar.claimCreationFailed')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
      })
      .finally(() => {
        commit('DO_NOTHING')
      })
  },
  async createCIClaim({ commit, dispatch }, claim) {
    MTPLService.createClaim(claim)
      .then((response) => {
        router.push(`/ci/${response.data.claim.id}`)
      })
      .catch((err) => {
        Sentry.captureMessage(`Create CARGO failed: ${err}`, (scope) => {
          scope.setExtras({ ...claim, stringified: JSON.stringify(claim) })
        })
        let snackbar = {
          text: `${i18n.t('snackbar.claimCreationFailed')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
      })
      .finally(() => {
        commit('DO_NOTHING')
      })
  },
  async createCASCOClaim({ commit, dispatch }, claim) {
    MTPLService.createClaim(claim)
      .then((response) => {
        router.push(`/casco/${response.data.claim.id}`)
      })
      .catch((err) => {
        Sentry.captureMessage(`Create CASCO failed: ${err}`, (scope) => {
          scope.setExtras({ ...claim, stringified: JSON.stringify(claim) })
        })
        let snackbar = {
          text: `${i18n.t('snackbar.claimCreationFailed')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
      })
      .finally(() => {
        commit('DO_NOTHING')
      })
  },
  async createShadowCASCOClaim({ commit }, claim) {
    return MTPLService.createClaim(claim).then((response) => {
      commit('DO_NOTHING')
      return response.data.claim.id
    })
  },
  async createMTPLInjuredClaim({ commit, dispatch }, claim) {
    MTPLService.createClaim(claim)
      .then((response) => {
        router.push(`/mtplinjured/${response.data.claim.id}`)
      })
      .catch((err) => {
        Sentry.captureMessage(
          `Create MTPL Not Liable failed: ${err}`,
          (scope) => {
            scope.setExtras({ ...claim, stringified: JSON.stringify(claim) })
          }
        )
        let snackbar = {
          text: `${i18n.t('snackbar.claimCreationFailed')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
      })
      .finally(() => {
        commit('DO_NOTHING')
      })
  },
  async createKRDNAClaim({ commit, dispatch }, claim) {
    MTPLService.createClaim(claim)
      .then((response) => {
        router.push(`/krdna/${response.data.claim.id}`)
      })
      .catch((err) => {
        Sentry.captureMessage(`Create KRDNA failed: ${err}`, (scope) => {
          scope.setExtras({ ...claim, stringified: JSON.stringify(claim) })
        })
        let snackbar = {
          text: `${i18n.t('snackbar.claimCreationFailed')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
      })
      .finally(() => {
        commit('DO_NOTHING')
      })
  },
  async createCPClaim({ commit, dispatch }, claim) {
    MTPLService.createClaim(claim)
      .then((response) => {
        router.push(`/cp/${response.data.claim.id}`)
      })
      .catch((err) => {
        Sentry.captureMessage(`Create CP failed: ${err}`, (scope) => {
          scope.setExtras({ ...claim, stringified: JSON.stringify(claim) })
        })
        let snackbar = {
          text: `${i18n.t('snackbar.claimCreationFailed')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
      })
      .finally(() => {
        commit('DO_NOTHING')
      })
  },
  async createBCAClaim({ commit, dispatch }, claim) {
    MTPLService.createClaim(claim)
      .then((response) => {
        router.push(`/bca/${response.data.claim.id}`)
      })
      .catch((err) => {
        Sentry.captureMessage(`Create BCA failed: ${err}`, (scope) => {
          scope.setExtras({ ...claim, stringified: JSON.stringify(claim) })
        })
        let snackbar = {
          text: `${i18n.t('snackbar.claimCreationFailed')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
      })
      .finally(() => {
        commit('DO_NOTHING')
      })
  },
  async createOTHERClaim({ commit, dispatch }, claim) {
    MTPLService.createClaim(claim)
      .then((response) => {
        router.push(`/other/${response.data.claim.id}`)
      })
      .catch((err) => {
        Sentry.captureMessage(`Create OTHER failed: ${err}`, (scope) => {
          scope.setExtras({ ...claim, stringified: JSON.stringify(claim) })
        })
        let snackbar = {
          text: `${i18n.t('snackbar.claimCreationFailed')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, {
          root: true,
        })
      })
      .finally(() => {
        commit('DO_NOTHING')
      })
  },
  async getClaim({ commit }, claimId) {
    MTPLService.getClaim(claimId).then((response) => {
      checkRoute(
        router.history.current.name,
        response.data.claim.stage.pipelineId,
        claimId
      )

      commit('SET_CLAIM', response.data.claim)
    })
  },
  async getClaimsByClient({ commit }, clientId) {
    MTPLService.getClaimsByClient(clientId).then((response) => {
      commit('SET_CLAIMS', response.data.claims)
    })
  },
  async importClaims({ dispatch }, claims) {
    MTPLService.importClaims(claims)
      .then((response) => {
        let snackbar = {
          text: `Data was added successful.`,
          color: 'success',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
        //  router.push('/mtpldashboard/data')
        router.push(`/mtlp/${response.data.claim.id}`)
      })
      .catch(() => {})
  },

  async clearClaims({ commit }) {
    commit('CLEAR_MTPLS')
  },
}
const checkRoute = (name, pipelineId, claimId) => {
  const pipelineRoutes = [
    {
      pipelineId: 1,
      routeName: 'MTPLShow',
    },
    {
      pipelineId: 2,
      routeName: 'CMRShow',
    },
    {
      pipelineId: 3,
      routeName: 'CASCOShow',
    },
    {
      pipelineId: 4,
      routeName: 'MTPLInjuredShow',
    },
    {
      pipelineId: 5,
      routeName: 'KRDNAShow',
    },
    {
      pipelineId: 6,
      routeName: 'CPShow',
    },
    {
      pipelineId: 7,
      routeName: 'BCAShow',
    },
    {
      pipelineId: 8,
      routeName: 'CIShow',
    },
    {
      pipelineId: 9,
      routeName: 'OTHERShow',
    },
  ]
  if (
    pipelineRoutes.find((route) => route.pipelineId === pipelineId)
      .routeName !== name
  ) {
    router.push({
      name: pipelineRoutes.find((route) => route.pipelineId === pipelineId)
        .routeName,
      params: { id: claimId },
    })
  }
}

import ClientService from '../../services/ClientService'
import i18n from '@/plugins/i18n'

export const namespaced = true
export const state = {
  clients: [],
  client: null,
}
export const getters = {
  value: (state) => {
    return state.value
  },
}
export const mutations = {
  SET_CLIENTS(state, clients) {
    state.clients = clients
  },
  CLEAR_CLIENTS(state) {
    state.clients = []
  },
  ADD_CLIENT(state, client) {
    state.clients = [...state.clients, client]
  },
  REMOVE_CLIENT(state, clientId) {
    state.clients = state.clients.filter((client) => client.id != clientId)
  },

  DO_NOTHING() {},
}
export const actions = {
  updateValue({ commit }, payload) {
    commit('updateValue', payload)
  },
  async getClients({ commit }) {
    ClientService.getClients().then((response) => {
      commit('SET_CLIENTS', response.data.clients)
    })
  },
  async checkClientRegistrationNumber({ commit }, registrationNumber) {
    commit('DO_NOTHING')
    return ClientService.checkClientRegistrationNumber(registrationNumber)
      .then((response) => {
        return response.data.client
      })
      .catch((error) => {
        console.log(error)
      })
  },
  clearClients({ commit }) {
    commit('CLEAR_CLIENTS')
  },
  async updateClient({ dispatch }, client) {
    ClientService.updateClient(client)
      .then(() => {
        let snackbar = {
          text: `${i18n.t('snackbar.clientChangesSaved')}`,
          color: 'success',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
      })
      .catch(() => {
        let snackbar = {
          text: `${i18n.t('snackbar.clientChangesWereNotSaved')}`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
      })
  },
  async deleteClient({ commit, dispatch }, clientId) {
    return ClientService.deleteClient(clientId)
      .then((response) => {
        commit('REMOVE_CLIENT', clientId)
        let snackbar = {
          text: `${response.data.client.name} ${i18n.t(
            'snackbar.deletedSuccessfully'
          )}.`,
          color: 'success',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
        return response.data.client.id
      })
      .catch(() => {
        let snackbar = {
          text: `${i18n.t('snackbar.clientWasNotDeleted')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
      })
  },

  async createClient({ commit, dispatch }, client) {
    return ClientService.createClient(client)
      .then((response) => {
        commit('ADD_CLIENT', response.data.client)
        let snackbar = {
          text: `${i18n.t('snackbar.clientCreatedSuccessfully')}.`,
          color: 'success',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
        return response.data.client.id
      })
      .catch(() => {
        let snackbar = {
          text: `${i18n.t('snackbar.clientWasNotCreated')}.`,
          color: 'error',
        }
        dispatch('snackbar/addSnackbar', snackbar, { root: true })
      })
  },
}

import PipelineService from '../../services/PipelineService'

export const namespaced = true
export const state = {
  pipelines: [
    {
      id: 1,
      name: 'MTPL',
    },
    {
      id: 2,
      name: 'CMR',
    },
    {
      id: 3,
      name: 'CASCO',
    },
    {
      id: 4,
      name: 'MTPL Injured',
    },
    {
      id: 5,
      name: 'KRD/NA',
    },
    {
      id: 6,
      name: 'CP',
    },
    {
      id: 7,
      name: 'BCA',
    },
    {
      id: 8,
      name: 'CARGO',
    },
    {
      id: 9,
      name: 'OTHER',
    },
  ],
}
export const getters = {
  pipelines: (state) => {
    return state.pipelines
  },
  pipelineIdByName: (state) => (name) => {
    if (state.pipelines.length == 0) {
      return 0
    }
    return state.pipelines.find(
      (p) => p.name.toUpperCase() == name.toUpperCase()
    ).id
  },
}

export const mutations = {
  SET_PIPELINES(state, pipelines) {
    state.pipelines = pipelines
  },
}
export const actions = {
  getPipelines({ commit }) {
    PipelineService.getPipelines().then((response) => {
      commit('SET_PIPELINES', response.data.pipelines)
    })
  },
}

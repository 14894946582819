export const en = {
  app: {
    login: 'Login',
    logout: 'Logout',
    cookiePolicy: 'Cookie Policy',
  },
  userTabs: {
    profile: 'Profile',
    registration: 'Registration',
    userList: 'User List',
  },
  userRegistration: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'E-mail',
    phone: 'Phone',
    selectRole: 'Select Role',
    selectCompany: 'Select Company',
    selectPipelines: 'Select Pipeline',
    register: 'Register',
    resetForm: 'Reset Form',
    password: 'Password',
    repeatPassword: 'Repeat Password',
    forgotPassword: 'Forgot password',
    setPassword: 'Set password',
  },
  userProfile: {
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    save: 'Save',
    changePassword: 'Change password',
    passwordReset: 'Password Reset',
    changesRegisteredSuccessfully: 'changes registered successfully',
  },
  //Section English translations in claim wizards.
  wizard: {
    //Section labels
    general: 'General',
    location: 'Location',
    incidentDetails: 'Incident details',
    documents: 'Documents',
    //Steps
    steps: {
      general: 'General',
      location: 'Location',
      details: 'Details',
      documents: 'Documents',
    },
    //Bottom wizard buttons
    backToClaimList: 'Back to Claim List',
    saveChanges: 'Save changes',
    submit: 'Submit',
    previous: 'Previous',
    next: 'Next',
    //Field labels
    selectCompany: 'Select Company',
    claimId: 'Claim ID',
    createNewClient: 'Create new client',
    claimNumber: 'Claim Number',
    clientClaimNumber: 'Client Claim No.',
    otherClaimNumber: 'Other Claim No.',
    incidentDate: 'Incident Date',
    incidentTime: 'Incident Time',
    insuredVehicle: 'Insured vehicle',
    createNewVehicle: 'Create new vehicle',
    driver: 'Driver',
    createNewDriver: 'Create new driver',
    reportedDate: 'Reported Date',
    amountClaimed: 'Amount Claimed',
    amountPaid: 'Amount Paid',
    insuranceClaimNumber: 'Insurance Claim Number',
    outcome: 'Outcome',
    reasonForFailure: 'Reason for failure',
    incidentLocation: 'Incident Location',
    latitude: 'Latitude',
    longitude: 'Longitude',
    getCurrentLocation: 'Get Current Location',
    selectDamagedPropertyType: 'Select damaged property type:',
    incidentDescription: 'Incident Description',
    insuranceBeeCircumstances: 'Insurance Bee circumstances',
    cascoNeeded: 'CASCO needed?',
    goToCascoClaim: 'Go to CASCO claim',
    contacts: 'Contacts',
    notes: 'Notes',
    attachments: 'Attachments',
    downloadAll: 'Download All',
    selectNewFileCategory: 'Select new file category',
    calculationsReceivedDate: 'Calculations Received Date',
    registerInInsurance: 'Register in Insurance',
    //MTPL specific
    failureReasonList: {
      wrongContacts: 'Wrong contacts',
      disappearedInjuredParty: 'Disappeared injured party',
      nonNegotiatingInjuredParty: 'Non-negotiating injured party',
      clientNonCooperation: 'Client non-cooperation',
      damageReportedTooLate: 'Damage reported too late',
      proceduralRestrictions: 'Procedural restrictions',
      electronicDeclaration: 'Electronic declaration',
    },
    damagedPropertyTypes: {
      vehicle: 'Vehicle',
      truck: 'Truck',
      fence: 'Fence',
      roadBarrier: 'Road barrier',
      gates: 'Gates',
    },
    createCascoClaim: 'Create CASCO claim',
    //CASCO specific
    insuranceProvider: 'Insurance Provider',
    trailerRegistration: 'Trailer registration',
    trailerInsuranceNumber: 'Trailer insurance number',
    payoutReceived: 'Payout Received',
    rescueWork: 'Rescue work?',
    isThereDamageToThirdPartyProperty:
      'Is there damage to third party property?',
    thirdPartyDamage: 'Third Party Damage',
    //MTPL not liable
    culpritRegistration: 'Culprit registration No.',
    culpritCountry: 'Culprit country',
    outcomeForEmployees: 'Outcome for employees',
    damagedCargo: 'Damaged cargo?',
    acknowledgedResponsibility:
      'Acknowledged responsibility (Our Part%: Other Part%)',
    guilty: 'Guilty',
    notGuilty: 'Not guilty',
    //CMR
    cargoClaim: 'Cargo Claim',
    claimToThirdParty: 'Claim To Third Party',
    claimToEntrustedProperty: 'Claim To Entrusted Property',
    freightForwardingClaim: 'Freight Forwarding Claim',
    warehouseKeepersLiability: 'Warehouse Keepers Liability',
    factualCarrier: 'Factual Carrier',
    isUrgent: 'Answer for claimant within 24-72 hours',
    allowDirectContactWithClaimant: 'Allow direct contact with claimant',
    claimingParty: 'Claiming party',
    declaredForInsurerDate: 'Declared for insurer date',
    unloadingDate: 'Unloading Date',
    finalInvoiceReceived: 'Final Invoice Received',
    payoutDate: 'Payout Date',
    amountReduced: 'Amount Reduced',
    reserve: 'Reserve',
    reserveReceived: 'Reserve received',
    lossDocExpNotProvided: 'The expectation/loss document is not provided',
    amountRejected: 'Amount Rejected',
    loadingLocation: 'Loading Location',
    unloadLocation: 'Unload Location',
    cargoType: 'Cargo type:',
    vehicle: 'Vehicle',
    standardCargo: 'Standard cargo',
    addVehicle: 'Add vehicle',
    selectDamagedVehicleParts: 'Select damaged vehicle parts',
    make: 'Make',
    model: 'Model',
    vehicleCondition: 'Vehicle condition:',
    new: 'New',
    used: 'Used',
    removeVehicle: 'Remove vehicle',
    selectCargoType: 'Select cargo type',
    missingDamagedCargoWeight: 'Missing/damaged cargo weight (kgs)',
    missingDamagedCargoAmount:
      'Missing/damaged cargo amount (pallets/units/boxes/other)',
    standardCargoCircumstances: 'Standard cargo circumstances',
    vehicleCircumstances: 'Vehicle circumstances',
    claimToEntrustedPropertyCircumstances:
      'Claim to entrusted property circumstances',
    mandatoryDocuments: 'Mandatory documents',
    repairInService: 'Going to repair in service',
    requestEstimate: 'Request an insurance estimate',
    cargoVehicles: 'Cargo vehicles',
    // KRD/NA specific
    NAClaim: 'NA Claim',
    KRDClaim: 'KRD Claim',
    hospitalLocation: 'Hospital Location',
    insuredPersonForm: 'Insured person form',
    name: 'Name',
    email: 'Email',
    birthDate: 'Birth Date',
    phone: 'Phone',
    relativeName: 'Relative Name',
    relativePhone: 'Relative Phone',
    insuredPerson: 'Insured Person',
    createNewInsuredPerson: 'Create New Insured Person',
    beneficiaryIBAN: 'Beneficiary‘s bank account no.',
    expenses: 'Expenses',
    hospitalContacts: 'Hospital contacts (Phone/E-mail)',
    //Form buttons
    save: 'Save',
    cancel: 'Cancel',
    //Vehicle form
    insuredVehicleForm: 'Insured vehicle form',
    registration: 'Registration',
    modelYear: 'Model year',
    //Image Viewer
    imageViewer: 'Image Viewer',
    //Driver form
    driverForm: 'Driver form',
    // Validations
    timeFormatValidation: 'Time must be in HH:mm format',
    dateFormatValidation: 'Date must be in YYYY-MM-DD format',
    // CP Claim
    damagedObject: 'Damaged Object',
    // Stage
    selectPreviousStage: 'Select previous Stage',
    selectNextStage: 'Select next Stage',
    stage: 'Stage',
    // Validation labels
    client: 'Client',
    circumstance: 'Circumstance',
    subtypeCheckboxes: 'Subtype checkboxes',
    //Latest reminder label
    nextUpdateScheduled: 'Next update scheduled:',
    //CMR file category
    carriersCmrWaybill: 'Carrier‘s CMR waybill',
    proofThatCargoWasDeliveredToAWarehouse:
      'Proof that cargo was delivered to a warehouse',
    markColour: 'Mark colour',
    unmarkColour: 'Unmark colour',
    transferredToRepresentatives: 'Transferred to Representatives',
    uniqueVin: 'Unique VIN',
    reloadPage: 'Reload page',
    claimOutdatedError:
      'Claim has been updated. Your changes will be lost. Reload page.',
  },
  vehicleParts: {
    wholeVehicle: 'Whole vehicle',
    frontBumper: 'Front bumper',
    bottomOfTheFrontBumper: 'Bottom of the front bumper',
    rearBumper: 'Rear bumper',
    bottomOfTheRearBumper: 'Bottom of the rear bumper',
    hood: 'Hood',
    headlights: 'Headlights',
    taillights: 'Taillights',
    roof: 'Roof',
    frontWing: 'Front wing',
    rearWing: 'Rear wing',
    sill: 'Sill',
    'luggageLid/Tailgate': 'Luggage lid / tailgate',
    windshield: 'Windshield',
    backWindow: 'Back window',
    vent: 'Vent',
    frontDoorRightSide: 'Front door right side',
    rearDoorRightSide: 'Rear door right side',
    frontDoorLeftSide: 'Front door left side',
    rearDoorLeftSide: 'Rear door left side',
    underbody: 'Underbody',
    rim: 'Rim',
    tyre: 'Tyre',
    mirror: 'Mirror',
    antenna: 'Antenna',
  },
  standardCargoItems: {
    foodItems: 'Food items',
    'non-alcoholicBeverages': 'Non-alcoholic beverages',
    alcoholicBeverages: 'Alcoholic beverages',
    'metal/steel': 'Metal/steel',
    wood: 'Wood',
    sanitaryProducts: 'Sanitary products',
    medicialTechnology: 'Medicial technology',
    pharmaceuticalProducts: 'Pharmaceutical products',
    cosmeticItems: 'Cosmetic items',
    electronics: 'Electronics',
    chemicalProducts: 'Chemical products',
    constructionMaterials: 'Construction materials',
    'fabric/clothingItems': 'Fabric/Clothing items',
    agriculturalProducts: 'Agricultural products',
    paper: 'Paper',
    furniture: 'Furniture',
    glassItems: 'Glass items',
  },
  circumstances: {
    'Maneuvering in the company / parking area':
      'Maneuvering in the company / parking area',
    'While making turn in the road': 'While making turn in the road',
    'Backwards in the road': 'Backwards in the road',
    'Hit the end of another vehicle in the road':
      'Hit the end of another vehicle in the road',
    'Changing lanes in the road': 'Changing lanes in the road',
    'Hit standing vehicle (on the side of the road)':
      'Hit standing vehicle (on the side of the road)',
    'Unclear liability': 'Unclear liability',
    'Oil/fuel spilled': 'Oil/fuel spilled',
    Other: 'Other',
    Theft: 'Theft',
    Fire: 'Fire',
    'Exceeding of the delivery date': 'Exceeding of the delivery date',
    Fine: 'Fine',
    'Improper fastening made by driver': 'Improper fastening made by driver',
    'Improper packaging': 'Improper packaging',
    'Damage to the third party': 'Damage to the third party',
    'Road accident': 'Road accident',
    'Collision with building structures (bridge, viaduct, etc)':
      'Collision with building structures (bridge, viaduct, etc)',
    'Collision with platform at the time of loading':
      'Collision with platform at the time of loading',
    'Collision with platform at the time of delivery':
      'Collision with platform at the time of delivery',
    'Collision with platform during the transportation':
      'Collision with platform during the transportation',
    'Collision with tree branches': 'Collision with tree branches',
    Rockfall: 'Rockfall',
    Hail: 'Hail',
    'Damaged with fastening belt': 'Damaged with fastening belt',
    'Damage was not noticed by driver before take-over':
      'Damage was not noticed by driver before take-over',
    'Hidden damage': 'Hidden damage',
    'Damaged caused by third parties': 'Damaged caused by third parties',
    'Vehicle rolled off the trailer': 'Vehicle rolled off the trailer',
    'Missing accessories': 'Missing accessories',
    'Contaminated product': 'Contaminated product',
    'Wrong temperature': 'Wrong temperature',
    'Improper fastening made by sender': 'Improper fastening made by sender',
    'Improper unloading made by driver': 'Improper unloading made by driver',
    'Improper unloading made by consignee':
      'Improper unloading made by consignee',
    'Improper loading made by driver': 'Improper loading made by driver',
    'Improper loading made by sender': 'Improper loading made by sender',
    'Water damage': 'Water damage',
    'Freight forwarding claim': 'Freight forwarding claim',
    'Breakdown on the road': 'Breakdown on the road',
    'Crashed into third party property ': 'Crashed into third party property',
    'Drove off the road': 'Drove off the road',
    'Glass damage': 'Glass damage',
    'Traffic accident': 'Traffic accident',
    'Burst tire': 'Burst tire',
    Animal: 'Animal',
    'During loading/unloading': 'During loading/unloading',
    'Hit the end of vehicle in the road': 'Hit the end of vehicle in the road',
    'Hit standing vehicle': 'Hit standing vehicle',
    'Unclear liabilty': 'Unclear liabilty',
    'At a roundabout': 'At a roundabout',
    'Mutual liability': 'Mutual liability',
    'Illegal person activity': 'Illegal person activity',
    'Damage due to sudden braking': 'Damage due to sudden braking',
    'Driver cannot explain': 'Driver cannot explain',
    'Shortage at the time of delivery': 'Shortage at the time of delivery',
    'Product contamination': 'Product contamination',
    'Damage due to cargo issues (inproper packaging or fastening)':
      'Damage due to cargo issues (inproper packaging or fastening)',
    'Sudden braking': 'Sudden braking',
    'Maneuvering in the company/parking area':
      'Maneuvering in the company/parking area',
    'Hit another vehicle/property': 'Hit another vehicle/property',
    'Lost control of the vehicle': 'Lost control of the vehicle',
    'Damage was noted by driver before take-over':
      'Damage was noted by driver before take-over',
    'Boulevard Périphérique': 'Boulevard Périphérique - Roundabout in Paris',
    'In a roundabout': 'In a roundabout',
    "Damage caused by a driver's faulty action":
      "Damage caused by a driver's faulty action",
  },
  validations: {
    clientRequired: 'Client is required.',
    ICNRequired: 'Insurance Claim Number is required.',
    ICNLength: 'Insurance Number must be at least 2 symbols.',
    driverRequired: 'Driver is required.',
    vehicleRequired: 'Vehicle is required.',
    emailValid: 'Must be valid e-mail',
    emailRequired: 'E-mail is required',
    companyNameRequired: 'Company name is required',
    phoneRequired: 'Phone is required',
    unloadDateRequired: 'Unloading date is required.',
    incidentDateRequired: 'Incident date is required.',
    loadingLocationRequired: 'Loading location is required.',
    unloadLocationRequired: 'Unload location is required.',
    nameRequired: 'Name is required.',
    insuredPersonRequired: 'Insured Person is required.',
    vehicleRegistrationRequired: 'Vehicle registration is required.',
    VINRequired: 'VIN is required.',
    VINAlphanumeric: 'VIN should be alphanumeric.',
    VINUnique: 'VIN must be unique.',
    fieldHasErrors: 'field has errors.',
    incidentDescriptionRequired: 'Incident description is required.',
    incidentLocationRequired: 'Incident location is required.',
    passwordLength: 'Passwords must be 8 characters or longer.',
    passwordMatch: 'Passwords must match.',
    dateRequired: 'Date is required.',
    KRDNACheckboxes: 'Please select at least one checkbox.',
    makeRequired: 'Make is required.',
    modelRequired: 'Model is required.',
    damagedPartsRequired: 'Damaged parts are required.',
    carriersCmrWaybillRequired: 'Carrier‘s CMR waybill is required.',
    proofThatCargoWasDeliveredToAWarehouseRequired:
      'Proof that cargo was delivered to a warehouse is required.',
    newFileCategoryIsRequired: 'New file category is required.',
    registrationNumberInUse: 'Company registration number is already in use.',
    registrationNumberRequired: 'Company registration number is required.',
  },
  fileCategories: {
    carriersCmrWaybill: 'Carrier‘s CMR waybill',
    carriersCmrInsurancePolicy: 'Carrier‘s CMR insurance policy',
    consigneesCmrWaybill: 'Consignee‘s CMR waybill',
    incidentDeclarationPolice: 'Incident declaration/police',
    vehicleTrailerRegistrationDocuments:
      'Vehicle/trailer registration documents',
    transportationOrder: 'Transportation order',
    defectAct: 'Defect act',
    photos: 'Photos',
    repairEstimateRepairInvoice: 'Repair estimate/repair invoice',
    repairEstimateRepairInvoiceOfTheTrailer:
      'Repair estimate/repair invoice of the trailer',
    writtenDriversExplanation: "Written driver's explanation",
    packingList: 'Packing list',
    invoiceOfTheCargo: 'Invoice of the cargo',
    surveyReport: 'Survey report',
    claimFinalInvoice: 'Claim/Final invoice',
    correspondenceWithClaimant: 'Correspondence with claimant',
    otherDocuments: 'Other documents',
    WrittenDriversExplanation: 'Written driver‘s explanation',
    repairEstimate: 'Repair estimate',
    repairInvoice: 'Repair invoice',
    vehiclesConditionReportBeforeLoading:
      'Vehicle‘s condition report before loading',
    vehiclesCommercialInvoice: 'Vehicle‘s commercial invoice',
    commercialInvoice: 'Commercial invoice',
    registrationDocuments: 'Registration documents',
    driversLicense: 'Drivers license',
    damagedVehiclePropertyPhotos: 'Damaged vehicle/property photos',
    requestForTransferringMoney: 'Request for transferring money documents',
    repairEstimateOrInvoice: 'Repair estimate or invoice',
    policeDocumentsOrAccidentStatement:
      'Police documents or accident statement',
    injuredPersonsPassportID: 'Injured person‘s passport/ID',
    residencePermit: 'Residence permit',
    businessTravelOrder: 'Business travel order',
    europeanHealthInsuranceCard: 'European Health Insurance Card (EHIC)',
    medicalDocuments: 'Medical documents',
    invoiceForMedicalServices: 'Invoice for medical services',
    deathCertificate: 'Death certificate',
    centreOfRegistersDocument: 'Centre of Registers document',
    photosOfTheDamagedItemObject: 'Photos of the damaged item/object',
    proofThatCargoWasDeliveredToAWarehouse:
      'Proof that cargo was delivered to a warehouse',
    rescueInvoice: 'Rescue invoice',
    documents: 'Documents',
  },
  //Titles
  title: {
    user: 'User',
    client: 'Client',
    activityLog: 'Activity Log',
    driver: 'Driver',
    vehicle: 'Vehicle',
    insuredPerson: 'Insured Person',
    claim: 'Claim',
    messages: 'Messages',
    login: 'Login',
    hive: 'Hive',
    clientView: 'Client View',
    file: 'File',
    selectInsuranceType: 'Select insurance type',
  },
  tabs: {
    data: 'Data',
    insight: 'Insight',
    import: 'Import',
    vehicle: 'Vehicle',
    vehicleImport: 'Vehicle import',
    driver: 'Driver',
    driverImport: 'Driver import',
    insuredPerson: 'Insured Person',
  },
  buttons: {
    claimRegistration: 'Claim registration',
    cancel: 'Cancel',
    ok: 'OK',
    save: 'Save',
    delete: 'Delete',
    login: 'Login',
    today: 'Today',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    days: '4 days',
    exportClaims: 'Export Claims',
    exportClientClaims: 'Export Client Claims',
    reset: 'Reset',
    changeFileCategory: 'Change File Category',
  },
  forms: {
    editUser: 'Edit User',
    editDriver: 'Edit Driver',
    clientForm: 'Client Form',
    companyName: 'Company name',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    address: 'Address',
    registrationNumber: 'Company registration number',
    taxNumber: 'Tax Number',
    bankInfo: 'Bank info',
    account: 'Account',
    bank: 'Bank',
    swift: 'SWIFT',
    newVehicle: 'New Vehicle',
    editVehicle: 'Edit Vehicle',
    newInsuredPerson: 'New Insured Person',
    editInsuredPerson: 'Edit Insured Person',
    provideEmailAddressForPasswordReset:
      'Provide email address for password reset',
    changeFileCategoryForm: 'File category change form',
  },
  headers: {
    name: 'Name',
    email: 'Email',
    role: 'Role',
    claim: 'Claim',
    client: 'Client',
    vehicleNumber: 'Vehicle Number',
    trailerRegistration: 'Trailer Registration',
    icn: 'ICN',
    incidentDate: 'Incident Date',
    birthDate: 'Birth Date',
    relativeName: 'Relative Name',
    relativePhone: 'Relative Phone',
    driver: 'Driver',
    circumstance: 'Circumstance',
    stage: 'Stage',
    insuredPerson: 'Insured Person',
    location: 'Location',
    phone: 'Phone',
    outcome: 'Outcome',
    insuranceProvider: 'Insurance Provider',
    duration: 'Duration',
    unloadingDate: 'Unloading Date',
    reserve: 'Reserve',
    firDuration: 'FIR Duration',
    krdClaim: 'KRD Claim',
    naClaim: 'NA Claim',
    paid: 'Paid',
    contacts: 'Contacts',
    cmr: 'CMR',
    registration: 'Registration',
    make: 'Make',
    model: 'Model',
    modelYear: 'Model year',
    year: 'Year',
    stageChangeDate: 'Stage Change Date',
    outcomeUpdated: 'Outcome updated',
    'VIN/CT': 'VIN/CT',
    mark: 'Mark',
    actions: 'Actions',
  },
  filters: {
    client: 'Client',
    stage: 'Stage',
    from: 'From',
    to: 'To',
    followed: 'Followed',
    open: 'Open',
    closed: 'Closed',
    new: 'New',
    suspended: 'Suspended',
    marked: 'Marked',
    currentYear: 'Current Year',
    standardCargo: 'Standard Cargo',
    vehicle: 'Vehicle',
    culpritRegistration: 'Culprit registration No.',
    outcome: 'Outcome',
    claimToThirdParty: 'Claim To Third Party',
    claimToEntrustedProperty: 'Claim To Entrusted Property',
    freightForwardingClaim: 'Freight Forwarding Claim',
    reportedDate: 'Reported Date',
    reportedDateFrom: 'Reported Date From',
    reportedDateTo: 'Reported Date To',
    unloadingDateFrom: 'Unloading Date From',
    unloadingDateTo: 'Unloading Date To',
    incidentDateFrom: 'Incident Date From',
    incidentDateTo: 'Incident Date To',
  },
  placeholders: {
    search: 'Search',
  },
  insuranceProviders: {
    culpritsInsuranceCompany: 'Culprit‘s insurance company',
    contractNotConcludedViaInsuranceBee:
      'Contract not concluded via Insurance Bee',
    other: 'Other',
  },
  dialogMessages: {
    itemDeleteConfirmation: 'Are you sure you want to delete this item?',
    fileDeleteConfirmation: 'Are you sure you want to delete this file?',
    vehicleDeleteThisVehicle: 'Are you sure you want to delete this vehicle?',
    couldNotUploadTheFile: 'Could not upload the file!',
    deleteConfirmation: 'Are you sure you want to delete',
    unsavedFile:
      'You have unsaved attachments. Are you sure you want to leave?',
    cascoClaimConfirmation:
      'Are you sure you want to create Casco Claim from this MTPL Claim?',
    stageChangeDialog: 'Select a stage to change for ',
  },
  imports: {
    importPreview: 'Import preview',
    importDrivers: 'Import drivers',
    importVehicles: 'Import vehicles',
    imported: 'Imported',
    failedToImport: 'failed to import',
    importClaims: 'Import claims',
    import: 'Import',
    clear: 'Clear',
  },
  followerBar: {
    contacts: 'Contacts',
    following: 'Following',
    follow: 'Follow',
    email: 'Email',
    phone: 'Phone',
  },
  impersonationBar: {
    youAreLoggedInAs: 'You are logged in as',
    andCurrentlyImpersonating: 'and currently impersonating',
  },
  messages: {
    deleteMessage: 'Delete Message',
    typeSomething: 'Type Something',
  },
  notificationBar: {
    newClaimCreated: 'New Claim created.',
    claimNumberHasBeenAssigned: 'Claim number has been assigned',
    claimStageHasChanged: 'Claim stage has changed',
    messageInClaim: 'Message in claim',
    fileChangesInClaim: 'File changes in claim',
    claim: 'Claim',
    showAll: 'Show all',
  },
  stageReminder: {
    claimReminder: 'Claim reminder',
    reminderDate: 'Reminder Date',
    reminderTime: 'Reminder Time',
    message: 'Message',
    setReminder: 'Set Reminder',
    updateReminder: 'Update Reminder',
    close: 'Close',
  },
  fileUpload: {
    dropFilesHere: 'Drop files here',
  },
  snackbar: {
    reminderDeletedSuccessfully: 'Reminder deleted successfully',
    reminderWasNotDeleted: 'Reminder was not deleted',
    reminderUpdatedSuccessfully: 'Reminder updated successfully',
    reminderWasNotUpdated: 'Reminder was not updated',
    failedToLogin: 'Failed to login',
    couldNotRetrieveClaimNumber: 'Could not retrieve Claim Number',
    registeredSuccessfully: 'registered successfully',
    createdSuccessfully: 'created successfully',
    reminderFor: 'Reminder for',
    reminderWasNotCreated: 'Reminder was not created',
    couldNotRegisterUser: 'Could not register user',
    clientChangesSaved: 'Client changes saved',
    clientChangesWereNotSaved: 'Client changes were not saved',
    deletedSuccessfully: 'deleted successfully',
    clientWasNotDeleted: 'Client was not deleted',
    clientCreatedSuccessfully: 'Client created successfully',
    clientWasNotCreated: 'Client was not created',
    couldNotImportDrivers: 'Could not import drivers',
    passwordSetSuccessfully: 'Password set successfully',
    loginWithNewPassword: 'Login with new password',
    passwordResetRequestSuccessful: 'Password reset request successful',
    passwordResetRequestUnsuccessful: 'Password reset request unsuccessful',
    claimDeletedSuccessfully: 'Claim deleted successfully',
    claimWasNotDeleted: 'Claim was not deleted',
    claimUpdatedSuccessfully: 'Claim Updated successfully',
    claimUpdateFailed: 'Claim update failed',
    claimCreationFailed: 'Claim create failed',
    couldNotImportVehicles: 'Could not import vehicles',
    couldNotCreateVehicle: 'Could not create vehicle. Check for duplicates',
    notificationsCouldNotBeDeleted: 'Could not delete notifications',
    notificationsDeletedSuccessfully: 'Notifications deleted successfully.',
    registrationNumberInUse: 'Company registration number is already in use.',
  },
  stages: {
    Open: 'Open',
    'Paid/Closed': 'Paid/Closed',
    'Missing contact details': 'Missing contact details',
    'Large claim': 'Large claim',
    'Reported by insurer': 'Reported by insurer',
    'Negotiating with claimant': 'Negotiating with claimant',
    'Negotiation failed': 'Negotiation failed',
    'Awaiting calculations': 'Awaiting calculations',
    'Calculations received': 'Calculations received',
    'Negotiating claim sum': 'Negotiating claim sum',
    'Agreed, but unpaid': 'Agreed, but unpaid',
    'Preparing payment documents': 'Preparing payment documents',
    'Awaiting payment': 'Awaiting payment',
    'Awaiting waiver': 'Awaiting waiver',
    'Registered with insurer': 'Registered with insurer',
    'Closed/Payment from insurer received':
      'Closed/Payment from insurer received',
    'Payout received': 'Payout received',
    'Insurance verification pending': 'Insurance verification pending',
    'Waiting for liability confirmation': 'Waiting for liability confirmation',
    'Liability declined': 'Liability declined',
    'Liability confirmed': 'Liability confirmed',
    'Waiting for payment confirmation': 'Waiting for payment confirmation',
    'Closed without payout': 'Closed without payout',
    'Shared liability': 'Shared liability',
    'No registration for insurer': 'No registration for insurer',
    'Missing documents': 'Missing documents',
    'Awaiting estimated costs': 'Awaiting estimated costs',
    'Sent for surveyor': 'Sent for surveyor',
    "Sent for insurer's review": "Sent for insurer's review",
    "Waiting for insurer's approval": "Waiting for insurer's approval",
    'Reduced/Closed with payment': 'Reduced/Closed with payment',
    "Closed/Don't exceed deductible": "Closed/Don't exceed deductible",
    "Reduced/Don't exceed deductible": "Reduced/Don't exceed deductible",
    'Successfully rejected': 'Successfully rejected',
    'Closed without payment': 'Closed without payment',
    'Large claim/survey opportunity': 'Large claim/survey opportunity',
    'Missing documents (LC)': 'Missing documents (LC)',
    'Awaiting estimated costs (LC)': 'Awaiting estimated costs (LC)',
    'Closed without payment/time bar': 'Closed without payment/time bar',
    'Cost confirmed - waiting for invoice':
      'Cost confirmed - waiting for invoice',
    'Awaiting response from the loading company':
      'Awaiting response from the loading company',
    'Awaiting police report': 'Awaiting police report',
    'Liability in dispute': 'Liability in dispute',
    'Paid (remaining claim for cargo)': 'Paid (remaining claim for cargo)',
    'Sent for insurer’s review': 'Sent for insurer’s review',
    "Claim is being handled by culprit's insurance":
      "Claim is being handled by culprit's insurance",
    'Closed/Payment from culprit insurer received':
      'Closed/Payment from culprit insurer received',
    Closed: 'Closed',
    'Missing documents from other parties':
      'Missing documents from other parties',
    'Missing documents from client': 'Missing documents from client',
    'Rejection sent to claimant': 'Rejection sent to claimant',
    'Waiting for damage estimate/invoice':
      'Waiting for damage estimate/invoice',
    'Closed without claims': 'Closed without claims',
    'Awaiting client‘s clarification': 'Awaiting client‘s clarification',
    'Awaiting information from insurer': 'Awaiting information from insurer',
    'Awaiting information from claimant‘s insurer':
      'Awaiting information from claimant‘s insurer ',
    'Resolving issue of liability': 'Resolving issue of liability',
    'Forwarded to MTPL - not liable': 'Forwarded to MTPL - not liable',
    'Court procedure': 'Court procedure',
    'Awaiting payment of Claim solution invoice':
      'Awaiting payment of Claim solution invoice',
    'Claim administration suspended': 'Claim administration suspended',
    'Cost confirmed, awaiting insurer’s payment':
      'Cost confirmed, awaiting insurer’s payment',
    'Reduced/closed without payment': 'Reduced/closed without payment',
    'In progress': 'In progress',
    'Closed-reduced': 'Closed-reduced',
    'Urgent-insurer’s answer required': 'Urgent-insurer’s answer required',
  },

  sideBarLinks: {
    MTPL: 'MTPL - liable',
    CMR: 'Freight Services Liability Insurance (CMR)',
    CASCO: 'CASCO',
    'MTPL Injured': 'MTPL - not liable',
    'KRD/NA': 'TI/PA',
    BCA: 'GL',
    CP: 'CP',
    CARGO: 'Cargo Insurance',
    OTHER: 'Other',
  },

  sideBarLinksTooltips: {
    MTPL: 'Motor Third Party Liability - liable',
    CMR: 'Freight Services Liability Insurance (CMR)',
    CASCO: 'CASCO',
    'MTPL Injured': 'Motor Third Party Liability - not liable',
    'KRD/NA': 'Travel insurance/Personal Accidents insurance',
    BCA: 'General civil liability',
    CP: 'Commercial property',
    CARGO: 'Cargo Insurance',
    OTHER: 'Other',
  },

  dataTable: {
    sortBy: 'Sort by',
  },

  // :label="$t('fileCategories.incidentDeclarationPolice')"

  // open: 'Open',
  // missingDocumentsFromClient: 'Missing documents from client',
  // sentForInsurersReview: 'Sent for insurer’s review',
  // 'closed/paymentForInsurerReceived': 'Closed/Payment for insurer received',
  // closedWithoutPayment: 'Closed without payment',
  // closed: 'Closed',
}
